//@import ".../fonts_samsung/.../fonts_samsung/.../fonts_samsung/node_modules/@fancyapps/fancybox/dist/jquery.fancybox";
//@import "plyr/plyr";
  //@import "bootstrap-dialog";


@import "../../../node_modules/ladda/css/ladda";
@import "../../../node_modules/bootstrap-select/sass/bootstrap-select";

/************************************/
/* SamsungOneRegular - 400 - REGULAR */
@font-face {
  font-family: 'SamsungOne';
  src: url('../fonts_samsung/SamsungOne-400.eot');
  src: url('../fonts_samsung/SamsungOne-400.eot?#iefix') format('embedded-opentype'),
  url('../fonts_samsung/SamsungOne-400.woff2') format('woff2'),
  url('../fonts_samsung/SamsungOne-400.woff') format('woff'),
  url('../fonts_samsung/SamsungOne-400.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/************************************/
/* SamsungOneBold - 700 - BOLD */
@font-face {
  font-family: 'SamsungOne';
  src: url('../fonts_samsung/SamsungOne-700.eot');
  src: url('../fonts_samsung/SamsungOne-700.eot?#iefix') format('embedded-opentype'),
  url('../fonts_samsung/SamsungOne-700.woff2') format('woff2'),
  url('../fonts_samsung/SamsungOne-700.woff') format('woff'),
  url('../fonts_samsung/SamsungOne-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/************************************/
/* SamsungSharpSans - Bold */
@font-face {
  font-family: 'SamsungSharpSans';
  src: url('../fonts_samsung/SamsungSharpSansBd.eot');
  src: url('../fonts_samsung/SamsungSharpSansBd.eot?#iefix') format('embedded-opentype'),
  url('../fonts_samsung/SamsungSharpSansBd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@import "bootstrap";
@import "custom";


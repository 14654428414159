body, html{
  min-height: 100svh;
}
body{
  font-family: SamsungOne, sans-serif;
  -webkit-line-clamp: 13;
  -webkit-box-orient: vertical;
  line-height: 1.33;
  font-weight: 300;
  color: #000;
  background-color: #FFF;
  text-rendering: optimizeLegibility;
  font-size: 1.125em;
}

header{
  .brand-logo{
    width: 18svw;
    max-width: 8rem;
    min-width: 6rem;
  }
}

main{
  .hero-visual{
    h1{
      font-size: rfs-fluid-value(3.7rem);
      @include media-breakpoint-up(xxl) {
        font-size: 4rem;
      }
      @include media-breakpoint-down(lg) {
        font-size: rfs-fluid-value(2.8rem);
      }
      @include media-breakpoint-down(md) {
        font-size: rfs-fluid-value(2rem);
      }
      @include media-breakpoint-down(sm) {
        font-size: rfs-fluid-value(1.4rem);
      }
    }
    h3{
      font-size: rfs-fluid-value(1.7rem);
      @include media-breakpoint-up(xxl) {
        font-size: 2rem;
      }
      @include media-breakpoint-down(lg) {
        font-size: rfs-fluid-value(1.4rem);
        color: $white;
      }
      @include media-breakpoint-down(md) {
        font-size: rfs-fluid-value(.8rem);
        color: $white;
      }
      @include media-breakpoint-down(sm) {
        font-size: rfs-fluid-value(.6rem);
        color: $white;
      }
    }
  }
}

.rfs-sm{
  h3{
    font-size: rfs-fluid-value(1.8rem);
    @include media-breakpoint-down(lg) {
      font-size: rfs-fluid-value(1.8rem);
    }
    @include media-breakpoint-down(sm) {
      font-size: rfs-fluid-value(1.2rem);
    }
  }
}

.music-frame-teaser{
  .rfs-sm{
    h3{
      @include media-breakpoint-down(lg) {
        font-size: rfs-fluid-value(1.5rem);
      }
      @include media-breakpoint-down(sm) {
        font-size: rfs-fluid-value(1rem);
      }
    }
  }
}

.h-44{
  height: 44%;
}

footer{
  .bg-target{
    padding: 23px 0 12px;
    background: #363636;
    border-top: 1px solid #d0d0d0;
    line-height: 1.32;
    .copyright{
      font-size: 12px;
      font-weight: normal;
      color: #a6a6a6;
      line-height: 1.75;
      word-break: break-word;
      margin-bottom: 1em;
    }
    ul.list-unstyled{
      line-height: 24px;
      margin-bottom: 1rem;
      li.list-inline-item{
        margin-right: 1rem;
        .footer-terms__link{
          font-size: 14px;
          text-decoration: none;
          color: $white;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }

  }
}

h1{
  margin-bottom: 4%;

  font-family: SamsungSharpSans, sans-serif;
  //font-weight: 700;
  line-height: 120%;
  font-size: 2.909em; /* 64 */
}
h2{
  margin-bottom: 2%;

  font-family: SamsungSharpSans, sans-serif;
  //font-weight: 700;
  font-size: 3.273em; /* 72 */
  line-height: 120%;
}

h3{
  margin-bottom: 4%;

  font-family: SamsungSharpSans, sans-serif;
  //font-weight: 700;
  font-size: 1.444em; /* 26 */
  line-height: 120%;

  font-size: 1.875em; /* 45 */
}

h4{
  font-family: SamsungSharpSans, sans-serif;
  //font-weight: 700;
  font-size: 1.167em; /* 21 */
  line-height: 120%;
}

h5{
  font-family: SamsungSharpSans, sans-serif;
  //font-weight: 700;
  font-size: 1.107em; /* 21 */
  line-height: 120%;
}

h6{
  font-family: SamsungSharpSans, sans-serif;
  //font-weight: 700;
  font-size: 1.067em; /* 21 */
  line-height: 120%;
}

.ql-align-center{
  text-align: center;
}

.ql-align-right{
  text-align: right;
}

.container-fluid{
  max-width: 1440px;
  @include media-breakpoint-down(sm) {
    padding-left: $grid-gutter-width * 0.5;
    padding-right: $grid-gutter-width * 0.5;
  }
}

.btn-samsung{
  padding-top: .5em;
  padding-bottom: .4em;

  svg{
    width: 1.6em;
    display: inline-block;
    top: -0.1em;
    position: relative;
    margin: -0.2em 0.2em -0.2em -0.2em;
    .fillme{
      fill: black;
    }
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
  &.btn-black{
    background-color: #000;
    color: #fff;
    svg{
      .fillme{
        fill: white;
      }
    }
    &:hover{
      background-color: #555;
    }
  }
  &.btn-white{
    background-color: #fff;
    color: #000;
    &:hover{
      background-color: #ddd;
    }
  }
}

.btn-samsung-text{
  font-family: SamsungSharpSans, sans-serif;
  font-weight: 700;
  color: #000!important;
  position: relative;
  top: -.2em;
  text-decoration: none!important;
  padding-left: 0;
  padding-right: 0;
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 2px;
    background-color: currentColor;
  }
  &:hover{
    transition-property: color,background-color,border-color,border-bottom-color;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(0.33,0,0.3,1);
    cursor: pointer;
    &:after{
      animation: cta-underline-animation .2s both;
      animation-timing-function: cubic-bezier(0.4,0,0.2,1);
      animation-delay: .1s;
    }
  }
}

.form-control{
  border-bottom: 2px solid $black;
  padding-bottom: .3rem;
  height: 3rem!important;
}

.form-floating{
  label{
    font-weight: bold;
    @include media-breakpoint-down(sm) {
      font-size:  rfs-fluid-value(.7rem);
    }
  }
  textarea.form-control{
    min-height: 1rem;
    height: auto;
    border-radius: 0;
  }
}
.input-group{
  .input-group-text{

  }
}
.form-check{
  .form-check-label{
    font-weight: 500;
  }
}
.form-label{
  font-weight: 500;
}

.bootstrap-select.rs-select{
  .dropdown-toggle{
    border-radius: 0;
    padding: 0.3em 0.3em 0 0;
    font-size: 1.125rem!important;

    .filter-option-inner-inner{
      padding: 0.3rem 0;
    }
    &:focus{
      outline: none !important;
      border-color: rgba($primary, 0.5) !important;
    }
    &.show{
    }
    &.bs-placeholder{
      &:hover, &:active, &:focus{
      }
      &.show{
      }
    }
  }
  &.floating{
    +label{
      transform: scale(.7) translateY(-.66rem) translateX(0) !important;
    }
    .dropdown-toggle{
      .filter-option-inner{
        transform: translateY(.6rem) !important;
      }
    }
  }

  ul.dropdown-menu{

    li{
      &:hover{
        a[role="option"]
        {

        }
      }
    }
  }
}

.mandatory{
  color: $red;
}
.form-check-label{
  position: relative;
  margin-top: .15em;
}
.fv-plugins-message-container.invalid-feedback{
  display: block;
}

small.extra-small{
  font-size: 80%;
}

.bg-gray-samsung-2{
  background-color: #EEEEEE;
}